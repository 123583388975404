import React from 'react'
import CountUp from 'react-countup'
import ScrollAnimation from 'react-animate-on-scroll'
import TrackVisibility from 'react-on-screen'
import SectionTitle from '../../components/sectionTitle/SectionTitle'
import GoogleMap from '../../components/contact/GoogleMap'

const ContenidoDirectorio = ({ wrapperClass }) => {
  return (
    <>
      <div className="hierarchy-section p-0">
        <h3 className="heading mt-5">CONSEJO DIRECTIVO</h3>
        <div className="h-wrap">
          <div>
            <div className="h-name">Lic. Gerardo Ley Ruiz</div>
            <div className="h-position">Presidente</div>
            <div className="h-line"></div>

          </div>
        </div>

        <div className="time-line mt-5">
          <div className="container position-relative">
  
            <div className="directorioSpacer"></div>

            <div className="row py-4 ">
                <div className="h-name">Dr. Raúl Rangel Rojo</div>
                <div className="h-position">Vicepresidente</div>
                <div className="h-name">Lic. María Antonia Martínez Chávez</div>
                <div className="h-position">Secretaria</div>
                <div className="h-name">Dr. Héctor Bustos Serrano</div>
                <div className="h-position">Pro Secretario</div>
                <div className="h-name">Agustín Pablo Celaya Hernández</div>
                <div className="h-position">Tesorero</div>
                <div className="h-name">Miguel Axel de la Torre Barrera</div>
                <div className="h-position">Pro Tesorero</div>
                <div className="h-name">Carlos Alonso Carral Montejano</div>
                <div className="h-position">Enlace Patronato</div>
                <div className="h-name">Dr. Leonel Susano Cota Araiza</div>
                <div className="h-position">Coordinador de Museografía</div>
                <div className="h-name">Dr. Jesús Leonardo Heiras Aguirre</div>
                <div className="h-position">Vocal</div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <h3 className="heading mt-5">PATRONATO</h3>
        <div className="h-wrap">
          <div>
            <div className="h-name">Quím. Jesús Del Palacio Lafontaine</div>
            <div className="h-position">Presidente</div>
            <div className="h-line"></div>
          </div>
          <div>
            <div className="h-name">Dra. Graciela Cordero Arroyo</div>
            <div className="h-position">Secretaria</div>
          </div>
        </div>

        <div className="time-line mt-5">
          <div className="container position-relative">
            <div className="timeline-line">
              <div className="timeline-line1"></div>
              <div className="timeline-line2"></div>
              <div className="timeline-line3"></div>
            </div>
            <div className="directorioSpacer"></div>

            <div className="row py-4 ">
              <div className="col-lg-4">
                <div className="h-name">
                  Ing. Jorge Antonio Guevara Escamilla
                </div>
                <div className="h-position">Vocal</div>
              </div>

              <div className="col-lg-4">
                <div className="h-name">Jaime Navarro Eguiarte</div>
                <div className="h-position">Vocal</div>
              </div>

              <div className="col-lg-4">
                <div className="h-name">Dr. Eduardo Backhoff Escudero</div>
                <div className="h-position">Vocal</div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <h3 className="heading">DIRECTORIO GENERAL</h3>

        <div className="h-wrap">
          <div>
            <div className="h-name">Arqlga. Julia Bendímez Patterson</div>
            <div className="h-position">Directora</div>
            <div className="h-position-email">director@caracolbc.org</div>
          </div>
        </div>

        <div className="time-line mt-5">
          <div className="container position-relative">
            <div className="timeline-line">
              <div className="timeline-line1"></div>
              <div className="timeline-line2"></div>
              <div className="timeline-line2b"></div>
              <div className="timeline-line3"></div>
            </div>
            <div className="directorioSpacer"></div>

            <div className="row py-4 ">
              <div className="col-lg-4">
                <div className="h-name">
                  Biol. Horacio Alejandro Real Escobedo
                </div>
                <div className="h-position">
                  Coordinador de Servicios Educativos
                </div>
                <div className="h-position-email">educativos@caracolbc.org</div>
              </div>

              <div className="col-lg-4">
              <div className="h-name">LCC. Mayra Alexandra Pérez Corral</div>
                <div className="h-position">COORDINADORA DE COMUNICACIÓN</div>
                <div className="h-position-email">mperez@caracolbc.org</div>
                <div className="h-line"></div>

                <div className="h-name">Daniela Abigail Nava Vargas</div>
                <div className="h-position">DISEÑADORA</div>
                <div className="h-position-email">dnava@caracolbc.org</div>
                
                <div className="h-name">Briana Estela Ruiz Soria</div>
                <div className="h-position">AUXILIAR DE DISEÑO</div>
                <div className="h-position-email">creativo@caracolbc.org</div>

                <div className="h-name">Mariela Larissa Gálvez Ramos</div>
                <div className="h-position">AUXILIAR DE MERCADOTECNIA Y REDES SOCIALES</div>
                <div className="h-position-email">mgalvez@caracolbc.org</div>
              
              </div>

              <div className="col-lg-4">
              <div className="h-name"></div>
                <div className="h-position">Administración</div>
                <div className="h-position-email">
                  administracion@caracolbc.org
                </div>
                <div className="h-line"></div>

                <div className="h-name">LAE Gabriela Hernández Ruiz</div>
                <div className="h-position">AUXILIAR ADMINISTRATIVO</div>
                <div className="h-position-email">
                ghernandez@caracolbc.org
                </div>

                <div className="h-name">Luis Carlos Ibarra Gay</div>
                <div className="h-position">AUXILIAR CONTABLE</div>
                <div className="h-position-email">
                contabilidadmuseo@caracolbc.org
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="time-line">
        <div className="container position-relative">
          <div className="row py-4 ">
            <div className="col-lg-6">
              <div className="timeline-line-half-1">
                <div className="timeline-line1"></div>
                <div className="timeline-line2b-short"></div>

                <div className="timeline-line3"></div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="timeline-line-half-1">
                <div className="timeline-line1"></div>
                <div className="timeline-line2b-short-right"></div>

                <div className="timeline-line3"></div>
              </div>
            </div>

            <div className="directorioSpacer"></div>
            <div className="row py-4 ">
              <div className="col-lg-3">
                <div className="h-position">
                  DIVULGACIÓN Y EDUCACIÓN CIENTÍFICA
                </div>
                <div className="h-name">Biól. Alejandro Ruiz Sillas</div>
                <div className="h-position-email">
                  divulgacionars@caracolbc.org{' '}
                </div>
                <div className="h-name">Biól. Mariana Novelo Bátiz</div>
                <div className="h-position-email">
                  divulgacionmnb@caracolbc.org{' '}
                </div>
                <div className="h-name">Oc. Ángeles Leah Álvarez Álvarez</div>
                <div className="h-position-email">lalvarez@caracolbc.org</div>
                <div className="h-name"> LCRP Ian Alejandro Gómez Torres</div>
                <div className="h-position-email">igomez@caracolbc.org </div>
                <br className='showon991'></br>
                <br className='showon991'></br>

              </div>
              <div className="col-lg-3">
                <div className="h-name">Paola Vazquez Castro</div>
                <div className="h-position">
                  AUXILIAR DE ATENCIÓN AL VISITANTE Y SERVICIO SOCIAL
                </div>
                <div className="h-position-email">paola@caracolbc.org</div>

                <div className="h-name">Odaly Yusmila Merlin</div>
                <div className="h-position">
                AUXILIAR DE ATENCIÓN AL VISITANTE
                </div>
                <div className="h-position-email">serviciosocial@caracolbc.org</div>
                <div className="h-name">Brenda Vanessa Pérez Grijalva</div>
                <div className="h-position">
                AUXILIAR DE ATENCIÓN AL VISITANTE
                </div>
                <div className="h-position-email">bgrijalva@caracolbc.org</div>

                <br></br>
              </div>
              
              <br></br>

              
 

              <div className="col-lg-3">
                <div className="h-name">Roberto Ruiz Barbosa</div>
                <div className="h-position">Jefe de Mantenimiento</div>
                <div className="h-position-email">
                mantenimiento@caracolbc.org
                </div>

                <div className="h-line-short"></div>
                <br className='hideon991'></br>
                <br className='showon991'></br>
                <div className="h-position">
                  AUXILIARES DE LIMPIEZA Y MANTENIMIENTO
                </div>
                <div className="h-name">Felicitas Fernández Domínguez</div>
                <div className="h-name">Ricardo Juan de Dios Gutiérrez Miranda</div>
                <div className="h-name">Tomás Eduardo Payan Oliveros</div>
                <div className="h-name">Dalia Gutiérrez Piza</div>
                <div className="h-name">José Maldonado Flores</div>
                <br className='showon991'></br>

              </div>
              <div className="col-lg-3 pb-5 mb-5">
                <div className="h-name">Nayrobi Guadalupe Guevara</div>
                <div className="h-position">ENCARGADA DE TAQUILLA</div>
                <br></br>

              </div>

            </div>
          </div>
        </div>
      </div>

      </div>

     
    </>
  )
}

export default ContenidoDirectorio
