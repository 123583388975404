import React, { Component } from 'react';

import { createBucketClient } from '@cosmicjs/sdk';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export default class GaleriaEventos extends Component {

    state = {
        eventos: null,
        canFetch: false,
        someTExt: ""
    }

    componentDidMount = async () => {

        const cosmic = createBucketClient({
            bucketSlug: 'caracol-production',
            readKey: 'XGUdTvwEsZcTgQtg65ZQtnGIttwRyLWxyTOJ27fKBCANTqrEps',
          });

          const data = await cosmic.objects
          .find({
            type: 'eventos',
          })


        this.setState({
            eventos: data.objects,
            canFetch: true,
            someTExt: 'downloaded'
        })


    }


    render() {
        console.log(this.state.eventos);

        return (
            <>
                <div id="section3" >
                    <div className="section-heading ">
                        <h3 className="title has-cara-blue is-heavy">EVENTOS </h3>
                    </div>

                    <OwlCarousel items={4} responsive={{ 0: { items: 1 }, 400: { items: 1 }, 800: { items: 3 }, 1100: { items: 5 }, 1400: { items: 5 }, }}
                        autoplayTimeout={5000} margin={0} dots={false} autoWidth={false} className="owl-theme slider-4" autoplay loop key={`carousel_${this.state.someTExt}`}>

                        {this.state.eventos && this.state.eventos.map(evento => (
                            <div className="item laimagenevento" key={evento.title}>
                                <div className=" card-2">
                                <a target="_blank" href={evento.metadata.enlace_de_el_evento} rel="noopener noreferrer">

                                    <h2>{evento.title}</h2>
                                    <h3> {evento.metadata.descripcion}</h3>
                                    <p>{evento.metadata.fecha_y_hora_del_evento}</p>
                                    <h3>+ ver más</h3>

                                    </a>
                                </div>
                                <a target="_blank" href={evento.metadata.enlace_de_el_evento} rel="noopener noreferrer">

                                    <img src={evento.metadata.imagen_de_portada.imgix_url} alt="" />
                                </a>
                            </div>
                        ))}

                    </OwlCarousel>

                </div>

            </>
        )
    }

}