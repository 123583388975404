import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';


const LinkBannerVisitanos = ({ wrapperClass }) => {
    return (
        <>
            <div className="container-fluid directrio-section">
            <div className="section-heading ">
                        <h3 className="title has-cara-blue is-heavy">ESPACIOS Y SERVICIOS </h3>
                    </div>
                <div className="row">
                    <div className="col-lg-4 p-0">
                        <div className="directrio-bg d-bg">
                            <div className="trans-abs">
                                <div className="trans-text">
                                    <h4 className="heading-carousel text-center">
                                        Escuelas
                                    </h4>
                                    <div className="d-hover">
                                        <p className="has-dark-color mb0">Acude con tu grupo escolar y vivan una experiencia única. Contamos con transporte.</p>
                                        <div><a href={process.env.PUBLIC_URL + `/Catalogoparaescuelas2025.pdf`} target="_blank" rel="noreferrer" download>+Consulta nuestro catálogo.<i className="icon-arrow-down-s-fill"></i></a></div>                                        

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 p-0">
                        <Link to={process.env.PUBLIC_URL + `/contactanos`}>
                            <div className="transparencia-bg d-bg">

                                <div className="trans-abs">
                                    <div className="trans-text">
                                        <h4 className="heading-carousel text-center">
                                            Recorridos guiados
                                        </h4>
                                        <div className="d-hover">
                                            <p className="has-dark-color mb0">Contamos con recorridos guiados por el museo para proveerte una experiencia didáctica y divertida.</p>
                                            <div className="font-weight-bold text-blue">+ SABER MAS</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-lg-4 p-0">
                        <div className="renta-bg d-bg">
                            <div className="trans-abs">
                                <div className="trans-text">
                                    <h4 className="heading-carousel text-center">
                                    Renta de auditorio
                                    </h4>
                                    <div className="d-hover">
                                        <p className="has-dark-color mb0">Puedes rentar nuestro auditorio para eventos privados o congresos.</p>
                                        <div><a href={process.env.PUBLIC_URL + `/renta_auditorio.pdf`} target="_blank" rel="noreferrer" download>+ SABER MAS<i className="icon-arrow-down-s-fill"></i></a></div>                                        

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LinkBannerVisitanos;