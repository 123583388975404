import React from 'react'
import Slider from './swiper'
import './styles.css'

export default function LineaDeTiempo() {
  let width = window.innerWidth;

  const settings = {
    effect: 'coverflow',
    centeredSlides: true,
    slidesPerView: (width<600)?1:1.7,
    coverflowEffect: {
      rotate: 0, // Slide rotate in degrees
      stretch: 200, // Stretch space between slides (in px)
      depth: 200, // Depth offset in px (slides translate in Z axis)
      modifier: 1, // Effect multipler
      slideShadows: false, // Enables slides shadows
    },
  }
  return (
    <div
      className={` bg-color-white  `}
    >
      <div className="lineatiempo-banner">
                LÍNEA DEL TIEMPO
            </div>
      <div className="container">
        <div className="row">
          <div id="section3" className="col-lg-12">
            <h2>.</h2>
            <br></br>
            <Slider settings={settings}>
              {/* {data.map(item => (*/}
              <img src="/images/lineatiempo/Linea del tiempo-01.png" />
              <img src="/images/lineatiempo/Linea del tiempo-02.png" />
              <img src="/images/lineatiempo/Linea del tiempo-03.png" />
              <img src="/images/lineatiempo/Linea del tiempo-04.png" />
              <img src="/images/lineatiempo/Linea del tiempo-05.png" />
              <img src="/images/lineatiempo/Linea del tiempo-06.png" />
              <img src="/images/lineatiempo/Linea del tiempo-07.png" />
              <img src="/images/lineatiempo/Linea del tiempo-08.png" />
              <img src="/images/lineatiempo/Linea del tiempo-09.png" />
              <img src="/images/lineatiempo/Linea del tiempo-10.png" />
              <img src="/images/lineatiempo/Linea del tiempo-11.png" />
              <img src="/images/lineatiempo/Linea del tiempo-12.png" />
              <img src="/images/lineatiempo/Linea del tiempo-13.png" />
              <img src="/images/lineatiempo/Linea del tiempo-14.png" />
              <img src="/images/lineatiempo/Linea del tiempo-15.png" />
              <img src="/images/lineatiempo/Linea del tiempo-16.png" />
              <img src="/images/lineatiempo/Linea del tiempo-17.png" />
              <img src="/images/lineatiempo/Linea del tiempo-18.png" />
              <img src="/images/lineatiempo/Linea del tiempo-19.png" />
              <img src="/images/lineatiempo/Linea del tiempo-20.png" />
              <img src="/images/lineatiempo/Linea del tiempo-21.png" />
              <img src="/images/lineatiempo/Linea del tiempo-22.png" />


              {/*}))}*/}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  )
}